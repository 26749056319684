import React, { useMemo } from 'react';
import { Drawer } from 'antd';
import { ProTable } from '@ant-design/pro-components';

import loadJsonByLang from '../components/loadJsonByLang';

import useStore from '../stores';

class GachaName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }

  setVisible = (value) => {
    this.setState({ visible: value })
  }

  showDrawer = () => {
    this.setVisible(true);
  };

  onClose = () => {
    this.setVisible(false);
  };
  render() {
    let { record, lang } = this.props;
    let gachaId = record.gacha_id;
    let gachaLink = "https://app.bungo-mayoi.jp/webview/gacha/detail.php?id=123456789012&gacha_id=" + gachaId;
    if (lang === 'tw')
      gachaLink = "https://app.bungo-tales.tw/webview/gacha/detail.php?id=123456789012&gacha_id=" + gachaId;
    else if (lang === 'cn')
      gachaLink = "https://bsd-app.boltrendgames.com/webview/gacha/detail.php?id=123456789012&gacha_id=" + gachaId;
    let gachaName = record.name;
    let visible = this.state.visible;

    return (
      <>
        <a
          onClick={this.showDrawer}
        >
          {gachaName}
        </a>
        <Drawer
          title={record.name}
          placement="right"
          width="calc(min(700px, 100vw))"
          onClose={this.onClose}
          open={visible}
        >

          <div className="gachaDetailContainer">
            <iframe style={{ border: 0, width: "100%", height: "90vh" }} src={gachaLink} />
          </div>
        </Drawer>
      </>
    )
  }
}

function Gacha() {
  const lang = useStore(s => s.lang)
  const data = useMemo(() => loadJsonByLang('gacha', lang), [lang])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) => <GachaName lang={lang} record={record} />,
    },
    {
      title: 'Start',
      dataIndex: 'open_start_date',
      sorter: {},
    },
    {
      title: 'End',
      dataIndex: 'open_end_date',
      sorter: {},
    },
  ];

  return (
    <ProTable
      params={{ lang: lang }}
      request={async ({ pageSize, current, lang, ...filters }, sort) => {
        //filter
        const result = data.filter(x => {
          for (var filter of Object.entries(filters)) {
            switch (filter[0]) {
              case 'name':
                if (!new RegExp(filter[1]).test(x[filter[0]])) {
                  return false
                }
                break
              default:
                if (x[filter[0]] !== filter[1]) {
                  return false
                }
            }
          }
          return true
        })
        //sort
        Object.entries(sort).forEach(x => {
          if (x[1] === 'ascend') {
            result.sort((a, b) => a[x[0]].localeCompare(b[x[0]]))
          } else if (x[1] === 'descend') {
            result.sort((a, b) => b[x[0]].localeCompare(a[x[0]]))
          }
        })

        return {
          data: result,
          success: true,
          total: result.length
        }
      }}
      columns={columns}
      rowKey='id'
      search={{
        filterType: 'light',
      }}
      toolbar={{
        settings: false
      }}
    ></ProTable>
  )
}

export default Gacha;
