import React, { useMemo,  useRef } from 'react';
import { ProTable } from '@ant-design/pro-components';
import loadJsonByLang from '../components/loadJsonByLang';
import useStore from '../stores';

function buildFilter(arr, keyword) {
  if (arr.length === 0) {
    return []
  }
  let a = arr.map(x => x[keyword]);
  a = Array.from(new Set(a));

  return a.map(s => {
    let obj = { value: s, text: s };
    return obj
  })
}
function Charas({ str, handleSearch, dataIndex }) {

  let arr = str;
  let thums = buildBoostThums(arr, handleSearch, dataIndex);
  return (
    <>
      {thums}
    </>
  );

};
function buildBoostThums(str, handleSearch, dataIndex) {
  if (!str || str.length == 0)
    return null;
  const arr = str.split('@');

  const onClick = e => {
    let name = e.target.alt;
    handleSearch(name);
  }
  return (
    <>
      {arr.map(x => {
        const _arr = x.split(':');
        const id = _arr[0];
        const name = _arr[1]
        if (!name || !name.length) {
          return '-'
        }
        return (
          <img key={id} onClick={onClick} style={{ width: 64 }} alt={name} src={"/dl/core_image_character_stats_thumbnail_" + id + "_thumbnail.png"} />)
      }
      )
      }
    </>
  )
}

function Skills() {
  const lang = useStore(s => s.lang)
  const data = useMemo(() => {
    const res = loadJsonByLang('skill', lang)
    const result = []
    for (var type of Object.entries(res)) {
      result.push(...type[1].map(x => ({ ...x, type: type[0] })))
    }
    return result
  }, [lang])

  const formRef = useRef()
  const actionRef = useRef()

  const setCharaFilter = (value) => {
    formRef.current.setFieldValue('characters', value)
    console.log(formRef.current.getFieldValue('characters'))
    actionRef.current.reload()
  }

  const columns = [
    {
      hideInTable: true,
      title: 'Skill Type',
      dataIndex: 'type',
      valueType: 'select',
      fieldProps: {
        options: [
          { value: 'leader_skills', label: 'Leader Skills' },
          { value: 'active_skills', label: 'Active Skills' },
          { value: 'sub_skills', label: 'Sub Skills' },
        ]
      },
      initialValue: 'leader_skills',
    },
    {
      title: 'Skill',
      dataIndex: 'detail',
      sorter: (a, b) => a.detail.localeCompare(b.detail),
    },
    {
      title: 'CD (Lv1)',
      dataIndex: 'cd1',
      valueType: 'digit',
    },
    {
      title: 'CD (Lv5)',
      dataIndex: 'cd5',
      valueType: 'digit',
    },
    {
      title: 'Charactor',
      dataIndex: 'characters',
      render: (text) => <Charas str={text} handleSearch={setCharaFilter} dataIndex='characters' />
    },
  ];

  return (
    <ProTable
      formRef={formRef}
      actionRef={actionRef}
      params={{ lang: lang }}
      request={async ({ pageSize, current, lang, ...filters }, sort) => {
        console.log({ data, filters })
        const charaFilter = formRef.current.getFieldValue('characters')
        if (charaFilter) {
          filters.characters = charaFilter
        }
        //filter
        const result = data.filter(x => {
          for (var filter of Object.entries(filters)) {
            switch (filter[0]) {
              case 'detail':
                if (!new RegExp(filter[1]).test(x[filter[0]])) {
                  return false
                }
                break
              case 'characters':
                if (!new RegExp(filter[1].replace(/[\[\]]/g, '.')).test(x[filter[0]])) {
                  return false
                }
                break
              default:
                if (x[filter[0]] !== filter[1].toString()) {
                  return false
                }
            }
          }
          return true
        })
        //sort
        Object.entries(sort).forEach(x => {
          if (x[1] === 'ascend') {
            result.sort((a, b) => a[x[0]] - b[x[0]])
          } else if (x[1] === 'descend') {
            result.sort((a, b) => b[x[0]] - a[x[0]])
          }
        })

        return {
          data: result,
          success: true,
          total: result.length
        }
      }}
      columns={columns}
      rowKey={() => Math.random()}
      search={{
        filterType: 'light',
      }}
      toolbar={{
        settings: false
      }}
    ></ProTable>
  )
}


export default Skills;
