import { useState, useMemo } from 'react';
import { Avatar, Card, Tag, Descriptions, Drawer, Tabs } from 'antd';
import { ProTable } from '@ant-design/pro-components';

import loadJsonByLang from '../components/loadJsonByLang';

import useStore from '../stores';

function buildFilter(arr, keyword) {
  if (arr.length === 0) {
    return []
  }

  let a = arr.map(x => x[keyword]);

  if (keyword === 'events') {
    a = a.map(x => x && x.length ? x.split(',') : '-').flat()
  }
  a = Array.from(new Set(a));
  a.sort((a, b) => a.localeCompare(b))
  return a.map(s => {
    let obj = { value: s, label: s };
    return obj
  })
}

function thum(url, showDrawer) {
  return (
    <Avatar
      size={64}
      shape="square"
      src={url}
      onClick={showDrawer}
    />
  )
}
function full(url) {
  return (
    <Card
      style={{ width: "calc(min(650px, 80vw))" }}
      cover={<img src={url} />}
    >
    </Card>
  )
}
function LeaderSkill(record) {
  return (
    <div>
      <Tag>{record.leader_skill_name}</Tag>
      <div>{record.leader_skill}</div>
    </div>
  )
}
function ActiveSkill(record) {
  if (record.active_skill2) {
    return (
      <div>
        <Tag>{record.active_skill_name}</Tag>
        <Tag color="blue">{record.wait_turn + " turns"}</Tag>
        <div>{record.active_skill}</div>
        <Tag>{record.active_skill2_name}</Tag>
        <div>{record.active_skill2}</div>
      </div>
    )
  }
  return (
    <div>
      <Tag>{record.active_skill_name}</Tag>
      <Tag color="blue">{record.wait_turn + " turns"}</Tag>
      <div>{record.active_skill}</div>
    </div>
  )
}
function SubSkill1(record) {
  return (
    <div>
      <Tag>{record.sub_skill1_name}</Tag>
      <div>{record.sub_skill1 ? record.sub_skill1 : "-"}</div>
    </div>
  )
}
function SubSkill2(record) {
  return (
    <div>
      <Tag>{record.sub_skill2_name}</Tag>
      <div>{record.sub_skill2 ? record.sub_skill2 : "-"}</div>
    </div>
  )
}

function DetailDescriptions({ record }) {
  let anotherDetail = record.is_another == 1 ? (
    <Descriptions.Item label="Detail (another)">{record.detail_another}</Descriptions.Item>
  ) : null

  return (
    <div className="site-card-wrapper">
      <Descriptions layout="horizontal" size="small" column={1} bordered>
        <Descriptions.Item label="Data">
          <Tag color="grey">{"No. " + record.codex_id}</Tag>
          <Tag color="green">{"HP: " + record.hp_max}</Tag>
          <Tag color="red">{"ATK: " + record.attack_max}</Tag>
          <div>{`${record.character} / ${record.rarity} / ${record.type} / ${record.style} / ${record.group}`}</div>
        </Descriptions.Item>
        <Descriptions.Item label="Leader Skill">{LeaderSkill(record)}</Descriptions.Item>
        <Descriptions.Item label="Active Skill">{ActiveSkill(record)}</Descriptions.Item>
        <Descriptions.Item label="Sub Skill 1">{SubSkill1(record)}</Descriptions.Item>
        <Descriptions.Item label="Sub Skill 2">{SubSkill2(record)}</Descriptions.Item>
        <Descriptions.Item label="Detail">{record.detail}</Descriptions.Item>
        {anotherDetail}
        <Descriptions.Item label="Voices">
          <ul style={{ paddingLeft: 10 }}>
            {record.voices?.split(':').map(x => <li key={x}>{x}</li>)}
          </ul>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

function DetailDrawer({ record, tab }) {
  const [open, setOpen] = useState(false)
  const onClick = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }
  const normalThumImgSrc = process.env.PUBLIC_URL + "/dl/core_image_character_stats_thumbnail_" + record.img_id + "_thumbnail.png";
  const anotherThumImgSrc = process.env.PUBLIC_URL + "/dl/core_image_character_stats_another_thumbnail_" + record.img_id + "_thumbnail.png";

  const anotherAvatar = record.is_another == 1 ? (
    thum(anotherThumImgSrc)
  ) : null

  const trigger = tab === 'img' ? (
    <>
      {thum(normalThumImgSrc)}
      {anotherAvatar}
    </>
  ) : record.name


  return (
    <>
      <a onClick={onClick}>
        {trigger}
      </a>

      <Drawer
        title={record.name}
        open={open}
        onClose={onClose}
        width="calc(min(700px, 100vw))"
        styles={{ body: { paddingTop: 0 } }}
      >
        <DetailTabs
          record={record}
          tab={tab}
        />
      </Drawer>
    </>
  )
}

function DetailTabs({ record, tab }) {
  const [_tab, setTab] = useState(tab || 'detail')

  const onChange = (key) => {
    setTab(key)
  };

  const normalFullImgSrc = process.env.PUBLIC_URL + "/dl/core_image_character_stats_" + record.img_id + "_full.png";
  const anotherFullImgSrc = process.env.PUBLIC_URL + "/dl/core_image_character_stats_another_" + record.img_id + "_full.png";

  const anotherFull = record.is_another == 1 ? (
    full(anotherFullImgSrc)
  ) : null

  const items = [
    {
      key: 'img',
      label: 'Image',
      children: (
        <div className="fullImageContainer">
          {full(normalFullImgSrc)}
          {anotherFull}
        </div>
      ),
    },
    {
      key: 'detail',
      label: 'Details',
      children: <DetailDescriptions record={record}></DetailDescriptions>,
    },
  ];

  return (
    <Tabs activeKey={_tab} items={items} onChange={onChange} />
  )
}

function Chara() {
  const lang = useStore(s => s.lang)

  const data = useMemo(() => loadJsonByLang('chara', lang))

  const selections = useMemo(() => {
    const obj = {}
    const keys = ['name', 'type', 'style', 'group', 'rarity', 'character', 'gender', 'events']
    for (var key of keys) {
      obj[key] = buildFilter(data, key)
    }
    return obj
  }, [lang])

  const columns = [
    {
      title: 'Charactor',
      hideInTable: true,
      dataIndex: 'character',
      key: 'character',
      valueType: 'select',
      fieldProps: { options: selections.character }
    },
    {
      title: 'Rarity',
      key: 'rarity',
      valueType: 'select',
      hideInTable: true,
      key: 'rarity',
      dataIndex: 'rarity',
      fieldProps: { options: selections.rarity }
    },
    {
      title: 'Type',
      valueType: 'select',
      key: 'type',
      hideInTable: true,
      dataIndex: 'type',
      fieldProps: { options: selections.type }
    },
    {
      title: 'Style',
      key: 'style',
      valueType: 'select',
      hideInTable: true,
      dataIndex: 'style',
      fieldProps: { options: selections.style }
    },

    {
      title: 'Group',
      key: 'group',
      valueType: 'select',
      hideInTable: true,
      dataIndex: 'group',
      fieldProps: { options: selections.group }
    },
    {
      hideInTable: true,
      title: 'Event',
      dataIndex: 'events',
      key: 'events',
      valueType: 'select',
      fieldProps: { options: selections.events }
    },

    {
      key: 'codex_id',
      valueType: 'digit',
      title: 'Id',
      responsive: ['md'],
      dataIndex: 'codex_id',
      sorter: {},
    },
    {
      hideInSearch: true,
      title: 'Img',
      render: (text, record) => <DetailDrawer record={record} tab='img'></DetailDrawer>
    },

    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) => <DetailDrawer record={record}></DetailDrawer>,
    },
    {
      hideInSearch: true,
      key: 'hp_max',
      title: 'HP',
      responsive: ['sm'],
      dataIndex: 'hp_max',
      sorter: {},
    },
    {
      hideInSearch: true,
      key: 'attack_max',
      title: 'ATK',
      responsive: ['sm'],
      dataIndex: 'attack_max',
      sorter: {},
    },
    {
      title: 'Leader Skill',
      responsive: ['lg'],
      dataIndex: 'leader_skill',
      key: 'leader_skill',
    },
    {
      title: 'Active Skill',
      responsive: ['lg'],
      dataIndex: 'active_skill',
      key: 'active_skill',
    },
    {
      hideInSearch: true,
      title: 'Sub Skill 1',
      responsive: ['xl'],
      dataIndex: 'sub_skill1',
      key: 'sub_skill1',
    },
    {
      hideInSearch: true,
      title: 'Sub Skill 2',
      responsive: ['xxl'],
      dataIndex: 'sub_skill2',
      key: 'sub_skill2',
    },
    {
      hideInTable: true,
      title: 'Sub Skill',
      key: 'sub_skill',
    },
  ];
  return (
    <ProTable
      params={{ lang: lang }}
      request={async ({ pageSize, current, lang, ...filters }, sort) => {
        //filter
        const result = data.filter(x => {
          for (var filter of Object.entries(filters)) {
            switch (filter[0]) {
              case 'name':
              case 'events':
              case 'leader_skill':
              case 'active_skill':
                if (!new RegExp(filter[1]).test(x[filter[0]])) {
                  return false
                }
                break
              case 'sub_skill':
                if (
                  !new RegExp(filter[1]).test(x['sub_skill1']) &&
                  !new RegExp(filter[1]).test(x['sub_skill2'])
                ) {
                  return false
                }
                break
              default:
                if (x[filter[0]] !== filter[1]) {
                  return false
                }
            }
          }
          return true
        })
        //sort
        Object.entries(sort).forEach(x => {
          if (x[1] === 'ascend') {
            result.sort((a, b) => a[x[0]] - b[x[0]])
          } else if (x[1] === 'descend') {
            result.sort((a, b) => b[x[0]] - a[x[0]])
          }
        })

        return {
          data: result,
          success: true,
          total: result.length
        }
      }}
      columns={columns}
      rowKey='id'
      search={{
        filterType: 'light',
      }}
      toolbar={{
        settings: false
      }}
    ></ProTable>
  )
}

export default Chara;
