import React from 'react';
import { Row, Col, Collapse } from 'antd';
import banner from './banner.json';
import useStore from '../stores';
const { Panel } = Collapse;

function genBanner(record, lang) {
  let imgLink = process.env.PUBLIC_URL + "/dl/core_image_banner_" + record.image_id + ".png";
  if (lang === 'tw' || lang === 'cn') {
    imgLink = process.env.PUBLIC_URL + "/dl/" + lang + "/core_image_banner_" + record.image_id + ".png";
  }

  return (
    <Col key={Math.random()} style={{ padding: 5 }}>
      <img alt={record.image_id} style={{ maxWidth: 280 }} src={imgLink} />
      <div>{record.start_date} ~ {record.end_date}</div>
    </Col>
  )

}

function Home() {
  const data = banner
  const jp = data.jp.map(x => genBanner(x, 'jp'))
  // const tw = data.tw.map(x => genBanner(x, 'tw'))
  // const cn = data.cn.map(x => genBanner(x, 'cn'))
  const lang = useStore(s => s.lang)
  return (<>
    <Row key="banner" justify='center'>
      <Col style={{ maxWidth: '1200px', width: '100%' }}>
        <Collapse defaultActiveKey={[lang]}>
          <Panel header="server: JP" key="jp">
            <Row justify="center">{jp}</Row>
          </Panel>
          {/* <Panel collapsible='disabled'  header="server: TW" key="tw">
          </Panel>
          <Panel collapsible='disabled' header="server: CN" key="cn">
          </Panel> */}
        </Collapse>
      </Col>
    </Row>
  </>
  )

}

export default Home;
