import { Layout, Menu, Row, Col } from 'antd';
import { Link } from "react-router-dom";
import { SettingOutlined } from '@ant-design/icons';
import useStore from '../stores';

function Navi() {
  const lang = useStore(s => s.lang)
  const setLang = useStore(s => s.setLang)

  const centerStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  };
  const langMap = { 'jp': '日本語', 'tw': '繁体中文', 'cn': '简体中文' }

  const onClick = (e) => {
    switch (e.key) {
      case 'jp':
      case 'tw':
      case 'cn':
        setLang(e.key)
        break
    }
  }
  const items = [
    { key: 'home', label: <Link to=''>Home</Link> },
    {
      key: 'chara-submenu',
      label: 'Charactors',
      children: [
        { key: 'chara', label: <Link to='chara'>Charactors</Link> },
        { key: 'lwf', label: <Link to='lwf'>Animations</Link> },
        { key: 'skill', label: <Link to='skill'>Skills</Link> },
      ]
    },
    { key: 'gacha', label: <Link to='gacha'>Gacha</Link> },
    { key: 'event', label: <Link to='event'>Events</Link> },
    { key: 'sceneCard', label: <Link to='sceneCard'>Scene Cards</Link> },
    {
      key: 'lang',
      label: langMap[lang],
      icon: <SettingOutlined></SettingOutlined>,
      children: [
        { key: 'jp', label: <span>日本語</span> },
        { key: 'tw', label: <span>繁体中文</span> },
        { key: 'cn', label: <span>简体中文</span> }
      ]
    },
  ]

  return (
    <Menu
      mode="horizontal"
      theme="dark"
      style={centerStyle}
      items={items}
      onClick={onClick}
    >
    </Menu >
  );

}

//Layer
const { Header, Content, Footer } = Layout;

export default function Layer({ contents }) {

  return (
    <Layout className="layout">
      <Header>
        <div className="logo"></div>
        <Navi />
      </Header>
      <Content style={{ padding: '0 0px' }}>
        <Row justify='center'>
          <Col span={24} style={{ maxWidth: 1200 }}>
            {contents}
          </Col>
        </Row>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Bungo-Mayoi Database - An unofficial site about 文豪ストレイドッグス迷ヰ犬怪奇譚</Footer>
    </Layout>
  )
}
