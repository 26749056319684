import React, { useState, useMemo } from 'react';
import { Card,  Drawer} from 'antd';
import { ProTable } from '@ant-design/pro-components';
import loadJsonByLang from '../components/loadJsonByLang';
import useStore from '../stores';

function buildFilter(arr, keyword) {
  if (arr.length === 0) {
    return []
  }
  let a = arr.map(x => x[keyword]);
  a = Array.from(new Set(a));

  return a.map(s => {
    let obj = { value: s, label: s };
    return obj
  })
}


function FullImage({ record }) {
  const [visible, setVisible] = useState(false);

  let normalThumImgSrc = process.env.PUBLIC_URL + "/dl/core_image_scene_card_thumbnail_" + record.scene_card_id + "_thumbnail.png";
  let normalFullImgSrc = process.env.PUBLIC_URL + "/dl/core_image_scene_card_" + record.scene_card_id + "_full.jpg";

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      {thum(normalThumImgSrc, showDrawer)}
      <Drawer
        title={record.name}
        placement="right"
        width="calc(min(700px, 100vw))"
        onClose={onClose}
        visible={visible}
      >

        <div className="fullImageContainer">
          {full(normalFullImgSrc, onClose)}
        </div>
      </Drawer>
    </>
  );
};

function thum(url, showDrawer) {
  return (
    <img
      style={{ width: 100 }}
      alt='image'
      src={url}
      onClick={showDrawer}
    />
  )
}
function full(url, onClose) {
  return (
    <Card
      style={{ width: "calc(min(650px, 80vw))" }}
      cover={<img alt='image' src={url} />}
      onClick={onClose}
    >
    </Card>
  )
}

function SceneCard() {
  const lang = useStore(s => s.lang)

  const data = useMemo(() => loadJsonByLang('sceneCard', lang))

  const selections = useMemo(() => {
    const obj = {}
    const keys = ['rarity']
    for (var key of keys) {
      obj[key] = buildFilter(data, key)
    }
    return obj
  }, [lang])

  const columns = [
    {
      title: 'Id',
      dataIndex: 'codex_id',
      defaultSortOrder: 'descend',
      valueType: 'digit',
      sorter: {}
    },
    {
      hideInSearch: true,
      title: 'Img',
      render: (text, record) => <FullImage record={record}></FullImage>
    },
    {
      title: 'Name',
      responsive: ['lg'],
      dataIndex: 'name',
    },
    {
      title: 'Rarity',
      responsive: ['md'],
      dataIndex: 'rarity',
      valueType: 'select',
      fieldProps: {
        options: selections.rarity
      }
    },
    {
      title: 'Skills',
      dataIndex: 'skills',
      render: (text, record) => {
        return text.split('@').map(x => {
          var img = x.split(':')[0]
          var str = x.split(':')[1]
          return <div key={str}>
            <img src={`${process.env.PUBLIC_URL}/dl/ui_image_common_icon_${img}.png`} />
            {str}
            <br /></div>
        })
      }
    }
  ];

  return (
    <ProTable
      params={{ lang: lang }}
      request={async ({ pageSize, current, lang, ...filters }, sort) => {
        console.log({ data, current })
        //filter
        const result = data.filter(x => {
          for (var filter of Object.entries(filters)) {
            switch (filter[0]) {
              case 'name':
              case 'skills':
                if (!new RegExp(filter[1]).test(x[filter[0]])) {
                  return false
                }
                break
              default:
                if (x[filter[0]] !== filter[1]) {
                  return false
                }
            }
          }
          return true
        })
        // sort
        Object.entries(sort).forEach(x => {
          if (x[1] === 'ascend') {
            result.sort((a, b) => a[x[0]] - b[x[0]])
          } else if (x[1] === 'descend') {
            result.sort((a, b) => b[x[0]] - a[x[0]])
          }
        })

        return {
          data: result,
          success: true,
          total: result.length
        }
      }}
      columns={columns}
      rowKey='codex_id'
      search={{
        filterType: 'light',
      }}
      toolbar={{
        settings: false
      }}
    ></ProTable>
  )
}

export default SceneCard;
