import { create } from "zustand";
import { persist } from 'zustand/middleware'

const useStore = create(
  persist(
    (set) => ({
      lang: 'jp',
      setLang: (value) => set({ lang: value }),
    }),
    { name: 'config-storage' }
  )
)

export default useStore