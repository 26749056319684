import React, { useMemo, useState } from 'react';
import { Table, Space, Tag, Descriptions, Drawer } from 'antd';
import { ProTable } from '@ant-design/pro-components';
import loadJsonByLang from '../components/loadJsonByLang';
import useStore from '../stores';

function buildFilter(arr, keyword) {
  if (arr.length === 0) {
    return []
  }
  let a = arr.map(x => x[keyword]);
  a = Array.from(new Set(a));

  return a.map(s => {
    let obj = { value: s, label: s };
    return obj
  })
}
function buildEventFilter(arr) {
  if (arr.length === 0) {
    return []
  }
  let result = [];

  let types = arr.map(x => x['event_type']);
  types = Array.from(new Set(types));

  for (const type of types) {
    let records = arr.filter(x => x['event_type'] === type);
    let names = Array.from(new Set(records.map(x => x['name'])));
    names = names.map(x => {
      let obj = { value: x, text: x };
      return obj
    });
    let filter = {
      text: type,
      value: type,
      children: names,
    }
    result.push(filter)
  }
  return result
}
function getBanner(record, lang) {
  let imgLink = process.env.PUBLIC_URL + "/dl/core_image_banner_" + record.event_banner_id + ".png";
  if (lang === 'tw' || lang === 'cn') {
    imgLink = process.env.PUBLIC_URL + "/dl/" + lang + "/core_image_banner_" + record.event_banner_id + ".png";
  }
  return (
    <>
      <img style={{ maxWidth: 200 }} src={imgLink} />
    </>
  )

}
function getEventData(record, arr) {
  let eventId = record['event_id_unique'];
  let result = arr.filter(x => x['event_id_unique'] == eventId);
  if (record.event_type === 'Daily') {
    eventId = record['event_id'];
    result = arr.filter(x => x['event_id'] == eventId);
  }
  return result
}
class BoostDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    let arr = this.props.arr;

    let thums = buildBoostThums(Array.from(new Set(arr.map(x => x.img_id))), this.showDrawer)
    let columns = [
      {
        align: 'center',
        title: 'Character',
        render: (record) => <Space>
          <img style={{ width: 64 }} src={"/dl/core_image_character_stats_thumbnail_" + record['img_id'] + "_thumbnail.png"} />
          <div>Skill Lv {record['skill_lv']}</div>
        </Space>,
      },
      {
        className: 'hidden',
        align: 'center',
        title: "Skill Lv",
        dataIndex: 'skill_lv',
      },
      {
        title: 'Boost Details',
        dataIndex: 'boost_effect',
        render: (text) => text.split(':').map(x => (
          x.length > 0 ? <div key={x}>・{x}</div> : ""
        ))
      },
    ]
    return (
      <>
        {thums}
        <Drawer
          placement="right"
          width="calc(min(700px, 100vw))"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={arr}
            showSorterTooltip={false}
            pagination={false}
            rowKey={record => record.img_id + record.skill_lv}
          />
        </Drawer>
      </>
    );
  }
};
function buildBoostThums(arr, showDrawer) {
  return (
    <>
      {arr.map(chara =>
      (<img key={chara} onClick={showDrawer} style={{ width: 64 }} src={"/dl/core_image_character_stats_thumbnail_" + chara + "_thumbnail.png"} />
      )
      )
      }
    </>
  )
}
class StageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    let arr = this.props.arr;
    let hidden = 'hidden';
    if (arr[0])
      hidden = arr[0].event_point ? 'stageCell' : 'hidden'
    let columns = [
      {
        title: 'Stage',
        render: (record) => <><span>{record.difficulty}</span><span>{' ' + record.name}</span></>,
      },
      {
        title: 'AP',
        dataIndex: 'ap',
      },
      {
        className: hidden,
        title: 'Event Pt(/AP)',
        render: (record) => <><span>{record.event_point}</span><span>{record.ap === '0' ? '' : ' (' + (record.event_point / record.ap).toFixed(2) + ')'}</span></>
      },
      {
        title: 'Lv Exp',
        dataIndex: 'lv_exp',
      },
      {
        title: 'Rank Exp',
        dataIndex: 'rank_exp',
      },
      {
        title: 'Training Pt',
        dataIndex: 'training_point',
      },
    ]
    return (
      <>
        <a onClick={this.showDrawer}>{arr.length} Stages</a>
        <Drawer
          title='Stages'
          placement="right"
          width="calc(min(700px, 100vw))"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={arr}
            showSorterTooltip={false}
            pagination={false}
            rowKey={() => Math.random()}
          />
        </Drawer>
      </>
    );
  };
}
class EventShopDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  }

  render() {
    let arr = this.props.arr;
    let columns = [
      {
        title: 'Item',
        render: (record) => getItemImage(record.item, record),
      },

      {
        title: 'Event Pt',
        dataIndex: 'event_point',
      },
      {
        title: 'Event Item',
        render: (record) => getItemImage(record.event_item),
      },
    ]
    return (
      <>
        <a onClick={this.showDrawer}>{arr?.length} Shop Items</a>
        <Drawer
          title={"Event Shop"}
          placement="right"
          width="calc(min(700px, 100vw))"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={arr}
            showSorterTooltip={false}
            pagination={false}
            rowKey={() => Math.random()}
          />
        </Drawer>
      </>
    );
  };
}
class EventBonusDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  }

  render() {
    let arr = this.props.arr;
    let columns = [
      {
        title: 'Item',
        render: (record) => getItemImage(record.bonus_item_list, record),
      },

      {
        title: 'Detail',
        dataIndex: 'event_point',
      },
    ]
    return (
      <>
        <a onClick={this.showDrawer}>{arr?.length} Bonus Items</a>
        <Drawer
          title={"Event Bonus"}
          placement="right"
          width="calc(min(700px, 100vw))"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={arr}
            showSorterTooltip={false}
            pagination={false}
            rowKey={() => Math.random()}
          />
        </Drawer>
      </>
    );
  };
}
class RankBonusDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  }

  render() {
    let arr = this.props.arr;
    let columns = [
      {
        title: 'Rank',
        render: (record) => <div>{record.rank_upper} ~ {record.rank_lower}</div>
      },
      {
        title: 'Item',
        render: (record) => getItemImage(record.bonus_item_list, record),
      },


    ]
    return (
      <>
        <a onClick={this.showDrawer}>{arr?.length} Rank Bonus</a>
        <Drawer
          title={"Rank Bonus"}
          placement="right"
          width="calc(min(700px, 100vw))"
          closable={true}
          onClose={this.onClose}
          open={this.state.visible}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={arr}
            showSorterTooltip={false}
            pagination={false}
            rowKey={() => Math.random()}
          />
        </Drawer>
      </>
    );
  };
}

class FloorBonusDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  }

  render() {
    let arr = this.props.arr;
    let columns = [
      {
        title: 'Stage',
        dataIndex: 'stage_id',
      },
      {
        title: 'Item',
        render: (record) => getItemImage(record.bonus_item_list, record),
      },
    ]
    return (
      <>
        <a onClick={this.showDrawer}>{arr?.length} Bonus Items</a>
        <Drawer
          title={"Event Bonus"}
          placement="right"
          width="calc(min(700px, 100vw))"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={arr}
            showSorterTooltip={false}
            pagination={false}
            rowKey={() => Math.random()}
          />
        </Drawer>
      </>
    );
  };
}
class FloorStageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  }

  render() {
    let arr = this.props.arr;
    let columns = [
      {
        title: 'Stage',
        render: (record) => <><span>{record.difficulty}</span><span>{' ' + record.name}</span></>,
      },
      {
        title: 'AP',
        dataIndex: 'ap',
      },
      {
        title: 'Lv Exp',
        dataIndex: 'lv_exp',
      },
      {
        title: 'Rank Exp',
        dataIndex: 'rank_exp',
      },
      {
        title: 'Training Pt',
        dataIndex: 'training_point',
      },
      {
        title: 'Max turn',
        dataIndex: 'max_turn',
      },
      {
        title: 'Item',
        render: (record) => getItemImage(record.item_list, record),
      },


    ]
    return (
      <>
        <a onClick={this.showDrawer}>{arr.length} Stages</a>
        <Drawer
          title={"Stages"}
          placement="right"
          width="calc(min(700px, 100vw))"
          closable={true}
          onClose={this.onClose}
          open={this.state.visible}
        >
          <Table
            size="small"
            columns={columns}
            dataSource={arr}
            showSorterTooltip={false}
            pagination={false}
            rowKey={() => Math.random()}
          />
        </Drawer>
      </>
    );
  };
}
function getItemImage(text, record) {
  if (!text)
    return null;
  const items = text.split('@');
  return (
    items.map(x => {

      const arr = x.split(':');
      const itemType = arr[0];
      const itemId = arr[5];
      const itemNum = arr[2];
      let img = '';
      switch (itemType) {
        case '1'://material
          img = process.env.PUBLIC_URL + "/dl/core_image_material_" + itemId + "_thumbnail.png";
          break;
        case '3'://chara
          img = process.env.PUBLIC_URL + "/dl/core_image_character_stats_thumbnail_" + itemId + "_thumbnail.png";
          break;
        case '4'://gold
          img = process.env.PUBLIC_URL + "/dl/ui_image_common_thumbnail_thumbnail_gold.png";
          break;
        case '7'://scout ticket
          img = process.env.PUBLIC_URL + "/dl/core_image_scout_ticket_" + itemId + "_thumbnail.png";
          break;
        case '8': case '9': case '12'://stone
          img = process.env.PUBLIC_URL + "/dl/ui_image_common_thumbnail_thumbnail_unusual_stone.png";
          break;
        case '11'://event item
          img = process.env.PUBLIC_URL + "/dl/core_image_event_item_" + itemId + "_thumbnail.png";
          break;
        case '16'://emblem
          img = process.env.PUBLIC_URL + "/dl/core_image_emblem_" + itemId + ".png";
          break;
        case '17'://ap item
          img = process.env.PUBLIC_URL + "/dl/core_image_ap_recovery_item_" + itemId + "_thumbnail.png";
          break;
        case '18'://scene card
          img = process.env.PUBLIC_URL + "/dl/core_image_scene_card_thumbnail_" + itemId + "_thumbnail.png";
          break;
        case '21'://skill lv up item
          img = process.env.PUBLIC_URL + "/dl/core_image_skill_lv_up_item_" + itemId + "_thumbnail.png";
          break;
        case '22'://armed
          img = process.env.PUBLIC_URL + "/dl/core_image_armed_thumbnail_" + itemId + "_thumbnail.png";
          break;
        case '23'://ap item
          img = process.env.PUBLIC_URL + "/dl/core_image_exchange_ticket_thumbnail_" + itemId + "_thumbnail.png";
          break;
        case '24'://profile bg
          img = process.env.PUBLIC_URL + "/dl/core_image_bg_profile_thumbnail_" + itemId + "_thumbnail.png";
          break;
        case '25'://ap item
          img = process.env.PUBLIC_URL + "/dl/core_image_event_special_item_" + itemId + "_thumbnail.png";
          break;
        case '27'://skip ticket
          img = process.env.PUBLIC_URL + "/dl/core_image_skip_ticket_" + itemId + "_thumbnail.png";
          break;


      }
      let itemName = arr[4];
      const changeNum = record ? (record['change_num'] ? <span>({record['change_num']})</span> : null) : null;

      return (
        <Space key={items.indexOf(x)} align="end">
          <img alt={itemName} style={{ zoom: 0.5 }} src={img}></img>
          <span style={{ paddingRight: "12px" }}>x{itemNum}{changeNum}</span>
        </Space>
      )
    })
  )

}

function EventDetailDrawer({ record }) {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onClick = () => setOpen(true)
  return (
    <>
      <a onClick={onClick}>{record.name}</a>
      <Drawer
        title={record.name}
        open={open}
        onClose={onClose}
        width="calc(min(700px, 100vw))"
        styles={{ body: { paddingTop: 0 } }}
      >
        <ExpandedRow record={record}></ExpandedRow>
      </Drawer>
    </>
  )
}


function ExpandedRow({ record }) {
  const lang = useStore(s => s.lang)
  //deserialize data
  const _boosts = record.boost?.split(',').map(x => {
    const arr = x.split('|')
    return {
      img_id: arr[0],
      skill_lv: arr[1],
      boost_effect: arr[2]
    }
  }) || [];
  const _stages = record.stages?.split(',').map(x => {
    const arr = x.split('|')
    return {
      ap: arr[0],
      rank_exp: arr[1],
      lv_exp: arr[2],
      training_point: arr[3],
      event_point: arr[4],
      stage_id: arr[5],
      name: arr[6],
      difficulty: arr[7]
    }
  });
  const isBoost = record["is_boost"] == 1 ? <Descriptions.Item label="Boost Characters"><BoostDetails arr={_boosts} /></Descriptions.Item> : null;
  const _shop = record.shop?.split(',').map(x => {
    const arr = x.split('|')
    return {
      item: arr[0],
      event_item: arr[1],
      change_num: arr[2],
      event_point: arr[3]
    }
  })
  const _bonus = record.bonus?.split(',').map(x => {
    const arr = x.split('|')
    return {
      event_point: arr[0],
      stage_id: arr[1],
      rank_upper: arr[2],
      rank_lower: arr[3],
      bonus_item_list: arr[4]
    }
  });

  const buildTags = () => {
    const tagNames = ['name', 'event_type'];

    let tags = [];
    for (const tagName of tagNames) {
      const obj = { [tagName]: record[tagName] }
      tags.push(obj);
    }
    return tags;
  }
  let stageComponent = (<StageDetails arr={_stages} />);
  /*
  if (record.event_type == "floor") {
    stageComponent = (<FloorStageDetails arr={_stages} />);
  }
  */
  let bonusComponent = () => {
    if (record.event_type === "ranking") {
      return <RankBonusDetails arr={_bonus} />
    } else if (record.event_type === "floor") {
      return <FloorBonusDetails arr={_bonus} />
    }
    return record.bonus ? <EventBonusDetails arr={_bonus} /> : ""
  }
  return (
    <div className="site-card-wrapper">
      <Descriptions layout="horizontal" size="small" column={1} bordered>
        <Descriptions.Item label="Banner">{getBanner(record, lang)}</Descriptions.Item>
        <Descriptions.Item label="Type"> {record.event_type} </Descriptions.Item>
        <Descriptions.Item label="Date">
          <Tag>{"Start: " + record.start_date}</Tag>
          <Tag>{"Middle: " + record.middle_date || '-'}</Tag>
          <Tag>{"End: " + record.end_date}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Detail">{record.detail}</Descriptions.Item>

        {isBoost}
        <Descriptions.Item label="Data">
          <Space size='large'>
            {stageComponent}
            {record.shop ? <EventShopDetails arr={_shop} /> : null}
            {bonusComponent()}
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

function Event() {
  const lang = useStore(s => s.lang)
  const data = useMemo(() => loadJsonByLang('event', lang), [lang])

  const selections = useMemo(() => {
    const obj = {}
    const keys = ['event_type']
    for (var key of keys) {
      obj[key] = buildFilter(data, key)
    }
    return obj
  }, [lang])

  const columns = [
    {
      hideInSearch: true,
      responsive: ['sm'],
      title: 'Img',
      render: (record) => getBanner(record, lang)
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) => <EventDetailDrawer record={record}></EventDetailDrawer>
    },
    {
      hideInSearch: true,
      title: 'Start',
      dataIndex: 'start_date',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.start_date.localeCompare(b.start_date),
    },
    {
      hideInSearch: true,
      responsive: ['sm'],
      title: 'End',
      dataIndex: 'end_date',
      sorter: (a, b) => a.end_date.localeCompare(b.end_date),
    },
    {
      responsive: ['md'],
      title: 'Event Type',
      dataIndex: 'event_type',
      valueType: 'select',
      fieldProps: { options: selections.event_type }
    },
  ];

  return (
    <ProTable
      params={{ lang: lang }}
      request={async ({ pageSize, current, lang, ...filters }, sort) => {
        console.log({ data })
        //filter
        const result = data.filter(x => {
          for (var filter of Object.entries(filters)) {
            switch (filter[0]) {
              case 'name':
              default:
                if (x[filter[0]] !== filter[1]) {
                  return false
                }
            }
          }
          return true
        })

        return {
          data: result,
          success: true,
          total: result.length
        }
      }}
      columns={columns}
      rowKey='id'
      search={{
        filterType: 'light',
      }}
      toolbar={{
        settings: false
      }}
    ></ProTable>
  )
}


export default Event;
