
var lwfLoader=window.lwfLoader;
var lwfElement;
var lwfSetting;
var displaySetting;
var paramCallback;
var pLwf;
//window.addEventListener('DOMContentLoaded', function() {
function setLwf(){
    //load lwf
	lwfLoader = window.lwfLoader;
	displaySetting = {
	displayDivId : 'stage',
	stageHAlign:-1,
	stageVAlign:-1,
	stageWidth : 800,
	stageHeight : 600,
	};
    
    
	
	//lwfLoader.debug = true;

	var render=lwfLoader.getRenderer();
	if(render!='useCanvasRenderer')
		lwfLoader.setRenderer('canvas');
	lwfLoader.useBackgroundColor = true;
	lwfLoader.backgroundColor = 0x00cccccc;
	lwfLoader.fitToScreen = true;
	lwfLoader.addInitializeHook(paramCallback);

	lwfLoader.rootOffset.x = 200;
	lwfLoader.rootOffset.y = 150;
	lwfLoader.setDisplaySetting(displaySetting);
	lwfLoader.setPreventDefaultBehaviour(true);
}
window.lwfElement=lwfElement;
window.lwfSetting=lwfSetting;
window.displaySetting=displaySetting;
window.paramCallback=paramCallback;
window.pLWF=pLwf;

export default setLwf;