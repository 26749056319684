import React, { useEffect, useMemo, useState } from 'react';
import { Table, Space, Drawer, Button, Menu, Dropdown, Divider, Row, Col, Radio, Slider } from 'antd';
import { DownOutlined, SettingOutlined, SwapOutlined, RedoOutlined, VerticalAlignTopOutlined, PlayCircleOutlined } from '@ant-design/icons';
import loadJsonByLang from '../components/loadJsonByLang';
import setLwf from './show_lwf';
import MenuItem from 'antd/lib/menu/MenuItem';
import useStore from '../stores';
import { ProTable } from '@ant-design/pro-components';

var assetPath = process.env.PUBLIC_URL + '/dl/';
var assetPath2x = process.env.PUBLIC_URL + '/dl/';
var assetPathQ = process.env.PUBLIC_URL + '/q_img/';
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
    default:
      return 0;
  }
}
function randomBg(arr) {
  let bglist = arr || [];
  let bgCount = bglist.length;
  if (bgCount > 0) {
    let num = randomNum(1, bgCount);
    let link = assetPath2x + bglist[num];
    return link;
  }
  return assetPath + 'ui_image_common_frame_frame_info_bg_04.png'
}
function randomMovie() {
  const movielist = ['attack', 'skill2', 'start', 'damage', 'skill1'];
  let num = randomNum(0, movielist.length - 1);
  window._pLwf.forEach((x) => x.gotoAndPlayMovie('_root', movielist[num]));
}

function buildFilter(arr, keyword) {
  if (arr.length === 0) {
    return []
  }
  let a = arr.map(x => x[keyword]);
  a = Array.from(new Set(a));

  return a.map(s => {
    let obj = { value: s, label: s };
    return obj
  })
}

function FullImage(record, updateLwf, updateDrawerVisible) {
  const showDrawer = () => {
    // updateLwf(record);
    // updateDrawerVisible(true);
  }
  return (
    <div>
      {
        record.img_id.split(',').map(x => {
          const qImgSrc = getThumUrl(x.trim(), record.mini_character_id, record.type);
          return getThum(qImgSrc, showDrawer, record.mini_character_id)
        })}
    </div>
  );
};
function getThumUrl(img_id, mini_character_id, type) {
  let normalThumImgSrc = assetPath + "core_image_character_stats_thumbnail_" + img_id + "_thumbnail.png";
  let qImgSrc = assetPathQ + mini_character_id + ".png";
  let armedThumImg = assetPath + "core_image_armed_thumbnail_" + mini_character_id + "_thumbnail.png";
  if (type === 'character')
    qImgSrc = normalThumImgSrc;
  if (type === 'armed')
    qImgSrc = armedThumImg;
  return qImgSrc;
}
function getThum(url, clickEvent, id) {
  const onError = e => {
    let t = e.target;
    t.className = 'noImage';
    t.id = id;
    t.onError = null;
  }
  return (
    <img
      key={url}
      style={{ zoom: 0.3 }}
      alt='image'
      src={url}
      onClick={clickEvent}
      onError={onError}
    />
  )
}

function setLwfAssets(record) {
  let mini_character_id = record.mini_character_id;
  let color_id = record.color_id;
  window.lwfSetting = {
    lwf: mini_character_id + ".lwf",
    prefix: assetPath,
    imageMap: {
      "replace_sd_effect_pack.png": "replace_sd_effect_pack.png",
      "replace_skill_trigger_effect.png": "animation_replace_image_replace_skill_trigger_effect" + color_id + ".png",
    },
  };
}

function stageEventBinding() {
  var canvas = document.getElementsByTagName('canvas')[0];
  canvas.oncontextmenu = function (e) { return false; }
  var stage = document.getElementById('stage');
  /* --托拽-- */
  var oX = 0;
  var oY = 0;
  /* --鼠标按下-- */
  stage.onmousedown = function (evt) {
    evt.preventDefault();
    var is_click = true;
    var startX = evt.clientX;
    var startY = evt.clientY;
    var canvasWidth = window.lwfLoader.stageWidth;
    var canvasHeight = window.lwfLoader.stageHeight;
    var stageWidth = canvas.style.width.replace('px', '');
    var stageHeight = canvas.style.height.replace('px', '');
    var pLWF = window._pLwf[window.lwfNow];
    var mousemoveTemp = document.onmousemove;
    var mouseupTemp = document.onmouseup;
    /* --鼠标移动-- */
    document.onmousemove = function (e) {
      e.preventDefault();
      var X = Math.floor((e.clientX - startX) / stageWidth * canvasWidth);
      var Y = Math.floor((e.clientY - startY) / stageHeight * canvasHeight);
      if (Math.abs(X) > 0 || Math.abs(Y) > 0)
        is_click = false;
      oX = pLWF.offsetX + X;
      oY = pLWF.offsetY + Y;
      pLWF.moveToMovie('_root', oX, oY);
    }
    // 鼠标抬起
    document.onmouseup = function () {
      if (is_click === true)
        randomMovie();
      window._pLwf[window.lwfNow].offsetX = oX;
      window._pLwf[window.lwfNow].offsetY = oY;
      document.onmousemove = mousemoveTemp;
      document.onmouseup = mouseupTemp;
    }
  }
  //mobile drage
  stage.ontouchstart = function (evt) {
    evt.preventDefault();
    var is_click = true;
    var startX = evt.targetTouches[0].clientX;
    var startY = evt.targetTouches[0].clientY;
    var canvasWidth = window.lwfLoader.stageWidth;
    var canvasHeight = window.lwfLoader.stageHeight;
    var stageWidth = canvas.style.width.replace('px', '');
    var stageHeight = canvas.style.height.replace('px', '');
    var pLWF = window._pLwf[window.lwfNow];
    var touchmoveTemp = document.ontouchmove;
    var touchendTemp = document.ontouchend;
    /* --鼠标移动-- */
    document.ontouchmove = function (e) {
      e.preventDefault();
      var X = Math.floor((e.targetTouches[0].clientX - startX) / stageWidth * canvasWidth);
      var Y = Math.floor((e.targetTouches[0].clientY - startY) / stageHeight * canvasHeight);
      if (Math.abs(X) > 1 || Math.abs(Y) > 1)
        is_click = false;
      oX = pLWF.offsetX + X;
      oY = pLWF.offsetY + Y;
      pLWF.moveToMovie('_root', oX, oY);
    }
    // 鼠标抬起
    document.ontouchend = function () {
      if (is_click === true)
        randomMovie();
      window._pLwf[window.lwfNow].offsetX = oX;
      window._pLwf[window.lwfNow].offsetY = oY;
      document.ontouchmove = touchmoveTemp;
      document.ontouchend = touchendTemp;
    }
  }
}
var paramCallback = (pElement) => {
  return {
    "callback":
    {
      "onLoad": function (pLwf) {
        pLwf.offsetX = 100;
        pLwf.offsetY = 150;
        pLwf.scaleX = 1;
        pLwf.scaleY = 1;
        window._pLwf.push(pLwf);
        stageEventBinding();
        var lwfs = document.getElementsByTagName('canvas');
        for (let i = 0; i < lwfs.length; i++)
          lwfs[i].id = 'canvas' + i;
        window._pLwf.sort((a, b) => a.stage.id.localeCompare(b.stage.id));
      }
    }
  };
};

function LwfDrawer({ currentRecord, bglist, open, setOpen, lwflist }) {
  const [chara, setChara] = useState([])
  const [currentBg, setCurrentBg] = useState('')

  const onClose = () => {
    setOpen(false);
  };

  const afterOpenChange = (open) => {
    if (open) {
      //prevent window._plwf sorting error
      //if(!window.lwfElement)
      //  records=records.sort((a,b)=>a.mini_character_id-b.mini_character_id);
      //initialize
      window._pLwf = [];
      window.lwfNow = 0;
      window.lwfCount = -1;
      window.lwfElement = document.getElementById('stage');
      var stageHeight = window.innerHeight;
      var stageWidth = Math.min(window.innerWidth, 1500);
      window.lwfLoader.stageHeight = Math.floor(stageHeight * 800 / stageWidth);
      window.lwfLoader.stageWidth = 800;
      addMultiLwf(currentRecord);
    }
    else {
      document.getElementById('stage').innerHTML = '';
      setChara([])
    }
  };
  const addMultiLwf = (records) => {
    records.forEach(record => {
      setLwfAssets(record);
      window.lwfLoader.addInitializeHook(paramCallback);
      window.lwfLoader.playLWF(window.lwfElement, window.lwfSetting);
      window.lwfCount = -1;
    })
    setChara(records)
  }
  const addLwf = (record) => {
    setLwfAssets(record);
    window.lwfLoader.addInitializeHook(paramCallback);
    window.lwfLoader.playLWF(window.lwfElement, window.lwfSetting);
    setChara([...chara, record])
    window.lwfCount = -1;
  }

  useEffect(() => {
    if (currentBg === '') {
      setCurrentBg(randomBg(bglist))
    }
  }, [currentBg])

  return (
    <Drawer
      placement="right"
      width="calc(min(1500px, 100vw))"
      onClose={onClose}
      open={open}
      afterOpenChange={afterOpenChange}
      styles={{
        body: { overflow: 'hidden' }
      }}
      style={{
        backgroundImage: 'url(' + assetPath + 'ui_image_common_frame_frame_info_bg_04.png' + ')',
        backgroundSize: 'cover',
        transition: 'background 0.4s ease-in-out'
      }}

    >
      <div className="lwfContainer">
        <div>
          <BgDropdown
            bglist={bglist}
            updateBg={setCurrentBg}
            randomBg={randomBg}
          />
        </div>
        <div className="animation_controls">
          <Button onClick={() => window._pLwf[window.lwfNow].gotoAndPlayMovie('_root', 'attack')}>
            attack
          </Button>
          <Button onClick={() => window._pLwf[window.lwfNow].gotoAndPlayMovie('_root', 'skill2')}>
            skill2
          </Button>
          <Button onClick={() => window._pLwf[window.lwfNow].gotoAndPlayMovie('_root', 'start')}>
            start
          </Button>
          <Button onClick={() => window._pLwf[window.lwfNow].gotoAndPlayMovie('_root', 'skill1')}>
            skill1
          </Button>
          <Button onClick={() => window._pLwf[window.lwfNow].gotoAndPlayMovie('_root', 'damage')}>
            damage
          </Button>

          <Row>
            <Col span={4} style={{ display: 'grid' }}>
              <Button
                type='primary'
                onClick={() => {
                  var lwf = window._pLwf[window.lwfNow];
                  var X = 0 - lwf.scaleX;
                  var Y = lwf.scaleY;
                  lwf.scaleX = X;
                  lwf.scaleToMovie('_root', X, Y);
                }}
              ><SwapOutlined />Flip</Button>
            </Col><Col span={8} push={1}>
              <Slider min={-2} max={2.0} defaultValue={1} step={0.1}
                onChange={(value) => {
                  var lwf = window._pLwf[window.lwfNow];
                  var X = value;
                  var Y = Math.abs(value);
                  lwf.scaleToMovie('_root', X, Y);
                  lwf.scaleX = X;
                  lwf.scaleY = Y;
                }}
              /></Col></Row>
          <Row>
            <Col span={4} style={{ display: 'grid' }}>
              <Button
                type='primary'
                onClick={() => {
                  var lwf = window._pLwf[window.lwfNow];
                  lwf.rotateToMovie('_root', 0);
                }}
              ><RedoOutlined />Rotate</Button>
            </Col><Col span={8} push={1}>
              <Slider min={0} max={360} defaultValue={0} step={1}
                onChange={(value) => {
                  var lwf = window._pLwf[window.lwfNow];
                  lwf.rotateToMovie('_root', value);
                }}
              /></Col></Row>
        </div>
        <div id="chara_controls">
          <CharaControls
            lwflist={lwflist}
            addLwf={addLwf}
            chara={chara} />
        </div>
        <div id={'stage'} style={{ backgroundImage: 'url(' + currentBg + ')', backgroundSize: 'cover', transition: 'background 0.4s ease-in-out' }} />
      </div>
    </Drawer>
  )
}


class BgDropdown extends React.Component {
  constructor(props) {
    super(props);
  }
  randomBgOnClick() {
    let bg = randomBg(this.props.bglist);
    this.props.updateBg(bg);
  }
  render() {
    const menu = (
      <Menu subMenuCloseDelay={1}><MenuItem>
        <Row style={{ maxHeight: '50vh', overflowY: 'scroll' }}>
          {this.props.bglist.map(x =>
            <Col key={x} style={{ padding: 5 }}><img src={assetPath + x} style={{ maxHeight: 150 }} onClick={() => this.props.updateBg(assetPath2x + x)} /></Col>
          )}
        </Row>
      </MenuItem></Menu>);
    return (
      <>
        <Dropdown overlay={menu} getPopupContainer={triggerNode => triggerNode.parentNode}>
          <Button>
            <SettingOutlined />BackGround <DownOutlined />
          </Button>
        </Dropdown>
        <Button onClick={() => this.randomBgOnClick()}>RandomBG</Button>
      </>
    )
  }
}

class CharaControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedChara: 0,
      chara: [],
    }
  }
  render() {
    return (
      <>
        <Divider type='vertical' />
        <Button
          onClick={
            () => {
              var stage = document.getElementById('stage');
              var canvas = document.getElementById('canvas' + window.lwfNow);
              stage.appendChild(canvas);
            }
          }
        ><VerticalAlignTopOutlined />Top</Button>
        <Radio.Group
          value={this.state.checkedChara}
          onChange={
            (e) => {
              const value = e.target.value;
              this.setState({ checkedChara: value })
              window.lwfNow = value;
            }
          }
        >
          {
            this.props.chara.map(x => {
              var thumUrl = getThumUrl(x.img_id.split(',')[0], x.mini_character_id, x.type);
              window.lwfCount++;
              const id = window.lwfCount;
              if (id === this.props.chara.length - 1)
                window.lwfCount = -1;
              var thum = <img src={thumUrl} style={{ maxHeight: '40px' }} />
              return <Radio key={id} value={id}>{thum}</Radio>
            })}
        </Radio.Group>
      </>
    )
  }
}
function Lwf() {
  const [currentRecord, setCurrentRecord] = useState([])
  const [drawerOpen, setDrawerOpen] = useState(false)

  const lang = useStore(s => s.lang)
  const { bg, lwf } = useMemo(() => loadJsonByLang('lwf', lang), [lang])
  const selections = useMemo(() => {
    return {
      type: buildFilter(lwf, 'type')
    }
  }, [lang])

  useEffect(() => {
    setLwf()
  }, [])

  const updateLwf = (record) => {
    setCurrentRecord([record])
  }

  const columns = [
    {
      hideInSearch: true,
      title: 'Img',
      key: 'mini_character_id',
      dataIndex: 'mini_character_id',
      align: 'center',
      render: (text, record) => FullImage(record, updateLwf, setDrawerOpen),
      sorter: {}
    },
    {
      hideInTable: true,
      dataIndex: 'type',
      title: 'type',
      valueType: 'select',
      fieldProps: {
        options: selections.type,
      },
      initialValue: 'character'
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
  ];

  return (
    <>
      <LwfDrawer
        currentRecord={currentRecord}
        lwflist={lwf}
        open={drawerOpen}
        setOpen={setDrawerOpen}
        bglist={bg}
      />
      <ProTable
        rowKey='mini_character_id'
        columns={columns}
        params={{ lang: lang }}
        request={async ({ pageSize, current, lang, ...filters }, sort) => {
          //filter
          const result = lwf.filter(x => {
            for (var filter of Object.entries(filters)) {
              switch (filter[0]) {
                case 'name':
                  if (!new RegExp(filter[1]).test(x[filter[0]])) {
                    return false
                  }
                  break
                default:
                  if (x[filter[0]] !== filter[1]) {
                    return false
                  }
              }
            }
            return true
          })
          //sort
          if (Object.entries(sort).length) {
            Object.entries(sort).forEach(x => {
              if (x[1] === 'ascend') {
                result.sort((a, b) => a[x[0]] - b[x[0]])
              } else if (x[1] === 'descend') {
                result.sort((a, b) => b[x[0]] - a[x[0]])
              }
            })
          } else {
            result.sort((a, b) => b['mini_character_id'] - a['mini_character_id'])
          }

          return {
            data: result,
            success: true,
            total: result.length
          }
        }}
        search={{
          filterType: 'light',
        }}
        toolbar={{
          settings: false
        }}
        rowSelection={{
          selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
          defaultSelectedRowKeys: [],
        }}
        tableAlertRender={({
          selectedRowKeys,
          selectedRows,
          onCleanSelected,
        }) => {
          setCurrentRecord(selectedRows)
          return (
            <Space size={24}>
              <span>
                {selectedRowKeys.length} items selected
                <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
                  clear
                </a>
              </span>
            </Space>
          );
        }}
        tableAlertOptionRender={() => {
          return (
            <Space size={16}>
              <Button
                type='primary'
                onClick={() => { setDrawerOpen(true) }}
                icon={<PlayCircleOutlined></PlayCircleOutlined>}>Play</Button>
            </Space>
          );
        }}
      ></ProTable>
    </>
  )
}

export default Lwf;
