import './App.css';
import './index.css';
import Layout from './components/Layout'
import { createHashRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from './pages/Home'
import Chara from './pages/Chara'
import Lwf from './pages/Lwf';
import Skills from './pages/Skills';
import Gacha from './pages/Gacha';
import Event from './pages/Event';
import SceneCard from './pages/SceneCard';

const routes = [
  {
    path: "/",
    element: <Layout contents={<Outlet />} />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "chara",
        element: <Chara />,
      },
      {
        path: "lwf",
        element: <Lwf />,
      },
      {
        path: "skill",
        element: <Skills />,
      },
      {
        path: "gacha",
        element: <Gacha />,
      },
      {
        path: "event",
        element: <Event />,
      },
      {
        path: "sceneCard",
        element: <SceneCard />,
      },
    ]
  },
]

const router = createHashRouter(routes)

function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
